import { useState } from "react";
import Topbar from "./Topbar";
import classes from "./Navbar.module.css";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Topbar />
      <div
        className={`${classes.bgradial} container-fluid sticky-top bg-dark shadow-sm px-5 pe-lg-0`}
      >
        <nav
          className={`${classes.bgradial} navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 `}
        >
          <Link to="/welcome" className="navbar-brand">
            <h1 className="m-0 display-4 text-uppercase text-white brand">
              <i className="bi bi-house-check-fill text-primary me-2"></i>Okay
            </h1>
          </Link>
          <button
            onClick={() => setOpen(!isOpen)}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={isOpen ? "navbar-collapse" : "collapse navbar-collapse"}
            id="navbarCollapse"
          >
            <div className="navbar-nav ms-auto py-0  text-uppercase">
              <NavLink
                onClick={() => setOpen(false)}
                to="/welcome"
                className={(navData) =>
                  navData.isActive
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                BERANDA
              </NavLink>
              <NavLink
                onClick={() => setOpen(false)}
                to="/about"
                className={(navData) =>
                  navData.isActive
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                TENTANG KAMI
              </NavLink>
              <NavLink
                onClick={() => setOpen(false)}
                to="/gallery"
                className={(navData) =>
                  navData.isActive
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                GALERI
              </NavLink>
              <NavLink
                onClick={() => setOpen(false)}
                to="/contact"
                className={(navData) =>
                  navData.isActive
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                KONTAK
              </NavLink>
              <a
                href="!#"
                className="nav-item nav-link bg-primary text-white px-5 ms-3 d-none d-lg-block"
              >
                HUBUNGI KAMI <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
