import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div
      className={`container-fluid bg-dark ${classes.footer} mt-5 pt-5 wow fadeIn`}
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <h1 className="text-white mb-4 brand">
              <i className="bi bi-house-check-fill text-primary me-3"></i>Okay
              Gallery
            </h1>
            <p>Solusi untuk segala kebutuhan bangunan anda!</p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-square btn-outline-primary me-1"
                href="https://www.instagram.com/okayponorogo/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="btn btn-square btn-outline-primary me-1"
                href="https://wa.me/6281320248805"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <h4 className="text-light mb-4">Kontak</h4>
            <p>
              <i className="fa fa-map-marker-alt me-3"></i>Jalan Trunojoyo Gang
              1 No. 11, Ponorogo, Jawa Timur
            </p>
            <p>
              <i className="fa fa-phone-alt me-3"></i>
              <a
                href="https://wa.me/6281320248805"
                target="_blank"
                rel="noreferrer"
              >
                +62 813 2024 8805
              </a>
            </p>
            <p>
              <i className="fa fa-envelope me-3"></i>sofianfs72@gmail.com
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a href="!#">okaygallerydesign.com</a>, All Right Reserved.
            </div>
            <div className="col-md-3 text-center text-md-end">
              Created By{" "}
              <a
                href="https://www.instagram.com/dwikyryan/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                @dwikyryan
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
