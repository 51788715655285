const Topbar = () => {
  return (
    <div className="container-fluid px-5 d-none d-lg-block">
      <div className="row gx-5">
        <div className="col-lg-4 text-center py-3">
          <div className="d-inline-flex align-items-center">
            <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h6 className="text-uppercase fw-bold">Our Office</h6>
              <span>Jalan Trunojoyo Gang 1, No. 11, Ponorogo, Jawa Timur</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-center border-start border-end py-3">
          <div className="d-inline-flex align-items-center">
            <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h6 className="text-uppercase fw-bold">Email Us</h6>
              <span>sofianfs72@gmail.com</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-center py-3">
          <div className="d-inline-flex align-items-center">
            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h6 className="text-uppercase fw-bold">Call / WhatsApp Us</h6>
              <span>
                <a
                  href="https://wa.me/6281320248805"
                  target="_blank"
                  rel="noreferrer"
                >
                  +62 813 2024 8805
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
