import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            404 Error
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <a className="text-white" href="!##">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a className="text-white" href="!##">
                  Pages
                </a>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                404 Error
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 className="display-1">404</h1>
              <h1 className="mb-4">Halaman Tidak Ditemukan 😥</h1>
              <p className="mb-4">
                Halaman yang anda cari tidak tersedia atau sedang dalam
                perbaikan. Kembali lagi ke beranda?
              </p>
              <Link
                to="/welcome"
                className="btn btn-primary py-3 px-5"
                href="/welcome"
              >
                Kembali ke beranda
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
