import React, { Suspense } from "react";
import "./index.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import NotFound from "./pages/NotFound";
import Spinner from "./component/Spinner";

const Main = React.lazy(() => import("./pages/Main"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const Gallery = React.lazy(() => import("./pages/Gallery"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));

function App() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" exact element={<Navigate to="/welcome" />} />
          <Route path="/welcome" element={<Main />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <Footer />
    </>
  );
}

export default App;
